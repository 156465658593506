import { ProfileRequestType, ProfileType } from '@dltru/dfa-models'
import { closeMessage, getProfileRequestType, openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { logout } from '@store/auth'
import { authSelector } from '@store/auth/selectors'
import { signData } from '@store/helper'
import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import { profileDetailsSlice, setQualify } from './index'
import { transactionDetailsSelectors } from '@store/sign/selectors'
import { setIsLoadingTransactionDetails, setItemsTransactionDetails, clearDataTransactionDetails } from '@store/sign'

function* getProfileDetails() {
    try {
        const profileData = yield* select(profileSelector.selectProfile)
        const uid = yield* select(authSelector.selectUserUid)
        if (!profileData && uid) {
            const { error, data } = yield* call(api.lib.getFullUser, uid)
            if (error || data?.error) {
                throw new Error(error ?? data?.error)
            }
            if (data?.item) {
                yield* put(profileDetailsSlice.actions.setUserInfo(data.item))
            }
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получении данных о профиле',
        })
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}

function* getAuthData() {
    try {
        const uid = yield* select(authSelector.selectUserUid)
        if (uid) {
            const { data, error } = yield api.lib.fetchAuthData(uid)
            if (error) {
                throw new Error(error)
            }
            yield* put(profileDetailsSlice.actions.setAuthData(data.item))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли проблемы при получении авторизационных данных',
        })
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}

function* getApprovalHistory() {
    try {
        const approvalHistory = yield* select(profileSelector.selectApprovalHistory)
        const uid = yield* select(authSelector.selectUserUid)
        if (!approvalHistory.length && uid) {
            const { data, error } = yield api.lib.getStatusesProfileService(uid)
            if (error) {
                throw new Error(error)
            }
            yield* put(profileDetailsSlice.actions.setApprovalHistory(data?.items ?? []))
        }
    } catch (error) {
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}

function* getQualifyInfo() {
    try {
        const uid = yield* select(authSelector.selectUserUid)
        if (uid) {
            const { data, error } = yield* call(api.lib.getQualifyInfo, uid)
            if (error) {
                throw new Error(error)
            }
            yield* put(profileDetailsSlice.actions.setQualifyInfo(data?.item ?? null))
        }
    } catch (error) {
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}

function* getOtherClient({
    payload,
}: ReturnType<typeof profileDetailsSlice.actions.getOtherClient>) {
    try {
        yield* put(profileDetailsSlice.actions.setIsLoading(true))
        const { error: errorType, data: dataTypes } = yield* call(
            api.lib.getProfileTypeByUserService,
            payload,
        )

        if (errorType) {
            throw new Error(errorType)
        }

        const profileTypes = dataTypes?.items.map(
            (profileType) => profileType.type,
        ) as ProfileType[]

        const { error: errorProfile, data: profile } = yield* call(
            api.lib.getPublicInformation,
            getProfileRequestType(profileTypes) as
                | ProfileRequestType.LEGAL_ENTITY
                | ProfileRequestType.BUSINESSMAN,
            payload,
        )

        if (errorProfile || profile?.error) {
            throw new Error(errorProfile ?? profile?.error)
        }
        if (profile?.item) {
            yield* put(
                profileDetailsSlice.actions.setOtherProfile({
                    profile: profile.item,
                    profileTypes,
                }),
            )
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получении данных о клиенте',
        })
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}
function* terminationUserPrefly({
    payload,
}: ReturnType<typeof profileDetailsSlice.actions.terminationUserPrefly>){
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const { error, data } = yield* call(api.lib.terminationByClient, { skid: payload })
        if (error || data?.error) {
            throw error || data?.error
        }
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* terminationUser({
    payload,
}: ReturnType<typeof profileDetailsSlice.actions.terminationUser>) {
    try {
        yield* put(profileDetailsSlice.actions.setIsLoading(true))
        
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.item) {
            yield* signData(data?.item?.status, payload.skid, data)
            yield* put(clearDataTransactionDetails())
            const { callback } = payload
            yield* put(logout({ callback, withTerminateSession: true }))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при исключении из реестра пользователей',
        })
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}

function* setQualifyHandler({ payload }: ReturnType<typeof setQualify>) {
    try {
        const { error, data } = yield* call(api.lib.requestQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            // TODO пказыавем рузультат или нет
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка  при запросе для установки квалификации',
        })
        yield* put(profileDetailsSlice.actions.setError(String(error)))
    } finally {
        yield* put(profileDetailsSlice.actions.setIsLoading(false))
    }
}

export function* profileDetails(): Generator<StrictEffect> {
    yield* takeLatest(profileDetailsSlice.actions.getProfileDetails.type, getProfileDetails)
    yield* takeLatest(profileDetailsSlice.actions.getAuthData.type, getAuthData)
    yield* takeLatest(profileDetailsSlice.actions.getApprovalHistory.type, getApprovalHistory)
    yield* takeLatest(profileDetailsSlice.actions.getQualifyInfo.type, getQualifyInfo)
    yield* takeLatest(profileDetailsSlice.actions.getOtherClient.type, getOtherClient)
    yield* takeLatest(profileDetailsSlice.actions.terminationUser.type, terminationUser)
    yield* takeLatest(profileDetailsSlice.actions.terminationUserPrefly.type, terminationUserPrefly)
    yield* takeLatest(setQualify.type, setQualifyHandler)
}
