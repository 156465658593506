import { StrictEffect } from "@redux-saga/types"
import { call, put, takeLatest } from "typed-redux-saga"
import { paymentsSchedulerSlice } from "."
import api from "@services/api"
import { openMessage } from "@dltru/dfa-ui"

function* getCalculatedCouponPaymentCalendar({ 
  payload
}: ReturnType<typeof paymentsSchedulerSlice.actions.addOnceInNMonthsDates>) {
  try {
    const { data } = yield* call(api.lib.getCouponPaymentCalendar, payload)
    if (data?.items) {
      yield* put(paymentsSchedulerSlice.actions.addDates(data.items))
    }
  } catch (error) {
    openMessage({
      type: 'error',
      message: 'Возникла ошибка при добавлении дат в график выплат',
    })
    yield* put(paymentsSchedulerSlice.actions.setError(String(error)))
  } finally {
    yield* put(paymentsSchedulerSlice.actions.setIsLoading(false))
  }
}

export function* paymentsScheculer(): Generator<StrictEffect> {
  yield* takeLatest(paymentsSchedulerSlice.actions.addOnceInNMonthsDates.type, getCalculatedCouponPaymentCalendar)
}