/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable unused-imports/no-unused-vars */
import { IOnceInNMonthsFrequencyData, getSortedUniqDates, TCouponPaymentDate } from "@dltru/dfa-models"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface InitialStateModel {
  isLoading: boolean
  error: string | null
  dates: TCouponPaymentDate[]
}

const initialState: InitialStateModel = {
  isLoading: false,
  error: null,
  dates: [],
}

export const paymentsSchedulerSlice = createSlice({
  name: 'paymentsScheduler',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setDates(state, action: PayloadAction<TCouponPaymentDate[]>) {
      state.dates = getSortedUniqDates(action.payload)
    },
    resetDates(state) {
      state.dates = []
    }, 
    saveEditedDates(state, action: PayloadAction<TCouponPaymentDate[]>) {
      state.dates = action.payload
    },
    addDates(state, action: PayloadAction<string[]>) {
      const newDates: TCouponPaymentDate[] = action.payload.map(date => ({ payment_date: date }))
      const sortedDates = getSortedUniqDates([...state.dates, ...newDates])
      state.dates = sortedDates
    },
    addOnceInNMonthsDates(state, action: PayloadAction<IOnceInNMonthsFrequencyData>) {}
  },
})

export default paymentsSchedulerSlice.reducer