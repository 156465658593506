/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import {
    IApprovalHistoryItem,
    IAuthData,
    IBusinessmanProfilePublic,
    IFullUser,
    IIndividualProfilePublic,
    ILegalEntityProfilePublic,
    ProfileType,
    QualifyInfoType,
    SetSetQualifiedResponseType,
} from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

interface IProfileDetails {
    isLoading: boolean
    error: string | null
    userInfo: IFullUser
    approvalHistory: IApprovalHistoryItem[]
    qualifyInfo: QualifyInfoType | null
    otherClientProfile:
        | ILegalEntityProfilePublic
        | IBusinessmanProfilePublic
        | IIndividualProfilePublic
        | null
    otherClientProfileTypes: ProfileType[] | null
}

const initialState: IProfileDetails = {
    isLoading: false,
    error: null,
    userInfo: {} as IFullUser,
    approvalHistory: [],
    qualifyInfo: null,
    otherClientProfile: null,
    otherClientProfileTypes: null,
}

namespace profileDetailsNS {
    export type SetOtherProfile = PayloadAction<{
        profile: IProfileDetails['otherClientProfile']
        profileTypes: IProfileDetails['otherClientProfileTypes']
    }>
}

export const profileDetailsSlice = createSlice({
    name: 'profileDetails',
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload
        },

        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setUserInfo(state, action: PayloadAction<IFullUser>) {
            state.isLoading = false
            state.error = null
            state.userInfo = action.payload
        },
        clearProfile(state) {
            state.error = null
            state.userInfo = {} as IFullUser
            state.qualifyInfo = null
        },
        setAuthData(state, action: PayloadAction<IAuthData>) {
            state.userInfo.authorize_data = action.payload
        },
        setApprovalHistory(state, action: PayloadAction<IApprovalHistoryItem[]>) {
            state.approvalHistory = action.payload
        },
        setQualifyInfo(state, action: PayloadAction<QualifyInfoType | null>) {
            state.qualifyInfo = action.payload
        },

        getProfileDetails: () => {},
        getAuthData: (_, action: PayloadAction<string>) => {},
        getApprovalHistory: () => {},
        getQualifyInfo: () => {},

        getOtherClient: (_, action: PayloadAction<string>) => {},
        setOtherProfile(state, action: profileDetailsNS.SetOtherProfile) {
            state.otherClientProfile = action.payload.profile
            state.otherClientProfileTypes = action.payload.profileTypes
        },
        clearOtherClient(state) {
            state.otherClientProfile = null
            state.otherClientProfileTypes = null
        },
        terminationUser: (_, action: PayloadAction<{ callback: () => void; skid: string }>) => {},
        terminationUserPrefly: (_, action: PayloadAction<string>) => {},
    },
})

export const setQualify = createAction<SetSetQualifiedResponseType>('register/setQualify')

export default profileDetailsSlice.reducer
