import { IEmissionOrder } from '@dltru/dfa-models'

const toRubSafe = (kop?: number): number => {
    if (!kop) {
        return 0
    }

    return kop / 100
}

export const backToFront = (order: IEmissionOrder): IEmissionOrder => {
    return {
        ...order,
        price_per_dfa: order.price_per_dfa
            ? toRubSafe(order.price_per_dfa)
            : toRubSafe(order.order_price),
        total_price: toRubSafe(order.total_price),
        repurchase_percent: order.repurchase_percent
            ? order.repurchase_percent / 100
            : order.repurchase_percent,
    }
}

export const backToFrontArray = (data: IEmissionOrder[]) => data?.map(backToFront) || []
